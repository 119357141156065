<template>

<app-content-box class="block" :not-padded="true">
		
	<div class="block-preview">

		<app-blocks :blocks="[block]" />

	</div>
		
	<div class="block-info">

		<div class="block-info-name">

			<app-icon :icon="$constants.block.icon[block.type]" class="block-info-name-icon" />

			<div class="block-info-name-text">{{ block.name }}</div>

		</div>

		<div class="block-info-actions">

			<app-icon v-tooltip="'Edit block'" icon="edit" class="block-info-actions-item is-primary" v-on:click.native="$emit('edit')" />
			<app-icon v-tooltip="'Delete block'" icon="delete" class="block-info-actions-item is-danger" v-on:click.native="$emit('delete')" />
			<app-icon v-tooltip="'Move up'" icon="caret-up" class="block-info-actions-item" v-on:click.native="$emit('up')" :class="{'is-disabled': first}" />
			<app-icon v-tooltip="'Move down'" icon="caret-down" class="block-info-actions-item" v-on:click.native="$emit('down')" :class="{'is-disabled': last}" />

		</div>

	</div>
	
</app-content-box>

</template>

<script>

export default {
	
	props: ['block', 'first', 'last']

}

</script>

<style scoped>

.block-preview {
	padding: 10px;
	pointer-events: none;
}

.block-preview >>> .blocks-item {
	margin-bottom: 0px;
}

.block-info {
	border-top: 1px solid #eee;
	padding: 10px;
	display: flex;
	justify-content: space-between;
}

.block-info-name {
	display: flex;
	align-items: center;
	height: 24px;
}

.block-info-name-icon {
	margin-right: 5px;
	font-size: 14px;
	background-color: #687589;
	color: #fff;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	text-align: center;
	line-height: 24px;
}

.block-info-name-text {
	font-size: 14px;
	color: #687589;
}

.block-info-actions {
	display: flex;
}

.block-info-actions-item {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	font-size: 14px;
	background-color: #acb4c2;
	color: #fff;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	margin-left: 5px;
}

.block-info-actions-item.is-disabled {
	opacity: 0.15;
	pointer-events: none;
}

.block-info-actions-item.is-primary {
	background-color: #4082d3;
}

.block-info-actions-item.is-danger {
	background-color: #c55b5b;
}

</style>
